import React from "react"

// components
import Layout from "../components/layout"
import Img from "gatsby-image"
import ImgSelector from "../components/imgSelector"

// icons
import { FaExternalLinkAlt } from "react-icons/fa"

// image-gallery
// import Swiper from "react-id-swiper"
// Version >= 2.4.0
import "swiper/css/swiper.css"

const blogPost = ({ pageContext }) => {
  const { title, date, link, cardImg, bodyText, galleryImages } = pageContext

  const arraydate = date.slice(0, 10).split("-")

  const formatedDate = [arraydate[1], arraydate[2], arraydate[0]].join(" / ")

  const formatedBody = bodyText.split("\n")

  // const params = {
  //   slideClass: "h-64 w-full",
  //   slidesPerView: 1,
  //   pagination: {
  //     el: ".swiper-pagination",
  //     clickable: true,
  //   },
  //   navigation: {
  //     nextEl: ".swiper-button-next",
  //     prevEl: ".swiper-button-prev",
  //   },
  // }

  return (
    <Layout>
      <section className="container mx-auto px-5 pt-20 pb-10 xl:w-128 lg:w-128 md:w-128">
        <p className="mb-2 xl:w-128 lg:w-128 w-full">{formatedDate}</p>
        <div className="xl:w-128 lg:w-128 w-full flex">
          <h1 className="text-2xl">{title}</h1>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-4 text-blue-600  hover:text-blue-800 duration-200 cursor-pointer text-xl"
          >
            <FaExternalLinkAlt className="" />
          </a>
        </div>

        <div className="xl:w-56 lg:w-56 md:w-56 w-full mb-6 rounded">
          {galleryImages && (
            <ImgSelector
            cardImg={cardImg}
            galleryImages={galleryImages}
            title={title}
          />
          )}
          {!galleryImages && (
            <Img
              fluid={cardImg.childImageSharp.fluid}
              className="w-64 mx-auto rounded"
            />
          )}
        </div>

        <span className="lg:w-128 xl:w-128 text-left">
          {formatedBody.map(p => (
            <p key={`${formatedBody.indexOf(p)}`}>{p}</p>
          ))}
        </span>
      </section>
    </Layout>
  )
}

export default blogPost
